
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogisticsAudit from "@/request/apis/api_logistics_audit";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {RealNameItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";

interface IState {
  list: RealNameItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  item?: RealNameItem
  reason: string
  show: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    // fixed: 'left'
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '性别',
    dataIndex: 'sex',
    key: 'sex',
  },
  {
    title: '身份证',
    dataIndex: 'card_number',
    key: 'card_number',
    width: 260
  },
  {
    title: '用户',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: '提交时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '审核时间',
    dataIndex: 'review_time',
    key: 'review_time',
  },
  {
    title: '来源',
    dataIndex: 'shop_admin_id',
    key: 'shop_admin_id',
  },
  {
    title: '驾驶证信息',
    dataIndex: 'drivinglicenseu',
    key: 'drivinglicenseu',
  },
  {
    title: '行驶证',
    dataIndex: 'courier',
    key: 'courier',
    width: 250
  },
  {
    title: '审核状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '图片',
    key: 'image',
  },
  {
    title: '操作',
    key: 'action',
    width: 200,
    // fixed: 'right'
  },
];

export default defineComponent({
  name: 'RealNameList',
  components: {EditOutlined, CheckOutlined, CloseOutlined},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      item: undefined,
      reason: '',
      visible: false,
      show: false,
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
        type: '', // 类型 1为用户，2为商家，不传全部
        shop_admin_id: null,
        name: ''
      },
    })

    const statusText = [{name: '审核中', color: 'orange'}, {name: '审核通过', color: 'green'}, {name: '审核未通过', color: 'red'}]


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      if (!params.type) {
        delete params.type
      }
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogisticsAudit.getRealNameList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 设置modal显示隐藏
    const setVisible = (item: RealNameItem | undefined, flg: boolean) => {
      state.visible = flg
      state.reason = ''
      if (item) {
        state.item = item
      } else {
        setTimeout(() => state.item = undefined, 300)
      }
    }

    // 审核
    const onAudit = async (item: RealNameItem, flg: boolean) => {
      if (!flg && !state.reason) {
        message.destroy()
        message.warning('请输入不通过的原因')
        return
      }
      state.loading = true
      const res = await ApiLogisticsAudit.auditReal({
        "id": item.id,
        "reason": flg ? "审核通过" : state.reason,
        "is_passed": flg
      })
      state.loading = false
      setVisible(item, false)
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        message.error(res?.msg || '审核失败');
      }
    }

    const setShow = (item: RealNameItem | undefined, flg: boolean) => {
      state.show = flg
      if (item) {
        state.item = item
      } else {
        setTimeout(() => state.item = undefined, 300)
      }
    }

    // 详情
    const goDetail = (item: RealNameItem) => {
      router.push({path: '/audit/real_name_detail', query: {id: item.id}}).then()
    }
    // 驾驶证详情
    const goDrivingDetail = (id: any) => {
      router.push({path: '/audit/driving_licence_detail', query: {id}})
    }
    // 行驶证详情
    const goLicenceDetail = (id: any) => {
      router.push({path: '/audit/licence_detail', query: {id}})
    }

    // 搜索
    const onSearch = (key: string, value: any) => {
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    // 时间格式化
    const formatTime = (time: any, showTime = true) => {
      if (time === '长期') {
        return time
      }
      return dateFormat(time * 1000, showTime)
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      statusText,
      goDetail,
      handleTableChange,
      onSearch,
      resetSearch,
      formatTime,
      onAudit,
      setVisible,
      setShow,
      goLicenceDetail,
      goDrivingDetail,
    }
  }
})
